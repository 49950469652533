
import {yedion_promos,selected_yedion_promos} from '../store'
import {selected_deals} from '../../Deals/Scripts/store'
import {projectFirestore} from '../../../../../../firebase/config'

const insert_news_promos_to_yedion = async() => {
    try{
        
        const promises = []
    
        for(const s_promo of selected_yedion_promos.value){
            const doc = projectFirestore.collection('Yedion').doc(s_promo.promoCode)
            promises.push(
                doc.set(s_promo)
            )
        }

        await Promise.all(promises)
        await init_yedion_promos()

    }catch(err){
        throw new Error(err)
    }
}

const init_yedion_promos = async() => {
    try{
        const docs = await projectFirestore.collection('Yedion').get()
        yedion_promos.value = docs.docs.map(doc=>doc.data())
    }catch(err){
        throw new Error(err)
    }
}

const delete_promo_from_db = async(promo) => {
    try{
        await projectFirestore.collection('Yedion').doc(promo.promoCode).delete()
        const index = yedion_promos.value.findIndex(_promo => _promo.promoCode == promo.promoCode)
        if(index != -1) yedion_promos.value.splice(index,1)
    }catch(err){
        throw new Error(err)
    }
}

const delete_marked_promos = async() => {
    try{
        const promises = []
        console.log('promos:',selected_yedion_promos.value);
        selected_deals.value.forEach(promo => {
            console.log(promo);
            promises.push(
                delete_promo_from_db(promo)
            )
        })
        await Promise.all(promises)
        await init_yedion_promos()
    }catch(err){
        throw new Error(err)
    }
}

const delete_the_yedion_totally = async() => {
    try{
        const promises = []
        const docs = projectFirestore.collection('Yedion').get()
        if(docs.empty) return

        docs.forEach(doc => promises.push(doc.ref.delete()))

        await Promise.all(promises);
    }catch(err){
        throw new Error(err)
    }
}

export{
    insert_news_promos_to_yedion,
    init_yedion_promos,
    delete_promo_from_db,
    delete_marked_promos,
    delete_the_yedion_totally
}